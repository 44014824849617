import 'bootstrap/dist/css/bootstrap.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './assets/css/site.min.css';
import './assets/fonts/font.style.css';
import { AppConstant } from './helper/app.contant';
import { ToastProvider } from './helper/provider/alert';
import { cloneData, isEmptyObj, saveTheme } from './helper/utility';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './styles.css';

const handler = {
  set: function (obj: any, prop: any, value: any) {
    obj[prop] = value;
    document.documentElement.setAttribute(prop, value);
    return true;
  }
};
export const themeProxy = new Proxy(cloneData(AppConstant.THEME_ATTRIBUTES), handler);

function toogleTheme() {
  let theme = localStorage.getItem(AppConstant.THEME_STORAGE_KEY) || '{}';
  theme = JSON.parse(theme) || {}
  if (isEmptyObj(theme)) {
    Object.keys(AppConstant.THEME_ATTRIBUTES).forEach((th: any) => {
      //@ts-ignore
      theme[th] = AppConstant.THEME_ATTRIBUTES[th];
    })
    saveTheme(theme);
  }
  Object.keys(theme).forEach((th: any) => { themeProxy[th] = theme[th] })
}

toogleTheme();


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ToastProvider >
      <App />
    </ToastProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
