import { menu } from "../interface/menu-interface";
import { userPermission } from "../interface/user";
import { UrlServices } from "./url.service";

export const MENU_ITEMS: menu[] = [
    {
        title: 'Dashboard',
        icon: 'pi pi-home',
        link: UrlServices.DASHBOARD_ROUTE,
    },
    {
        title: 'Donation',
        icon: 'pi pi-dollar',
        link: UrlServices.PAGES.DONATION_LIST,
        data: { permission: {} as userPermission },
    },
    {
        title: 'Sponsorship',
        icon: 'pi pi-users',
        link: UrlServices.PAGES.SPONSORSHIP_LIST,
        data: { permission: {} as userPermission },
    },
    {
        title: 'Monthly Report',
        icon: 'pi pi-file-export',
        link: UrlServices.PAGES.MONTHLY_REPORT_LIST,
        data: { permission: {} as userPermission },
    },
]