import React, { createContext, useContext, useState, useEffect } from 'react';
import { isEmptyObj, isEqual } from '../utility';
import { BehaviorSubject } from 'rxjs';
import { useNavigate } from 'react-router-dom';
import { UrlServices } from '../class/url.service';

export interface User {
    [key: string]: string | any;
}

interface AuthContextProps {
    currentUserValue: () => User;
    setApplicationUser: (user: User) => void;
    loginStatus: () => boolean;
    getToken: () => string;
    clearLocalStorage: () => void;
    clearSubscribe: () => void;
    logout: (returnUrl?: string) => void;
    redirectToLogin: () => void;
    redirectToDashboard: () => void;
    redirectToDenied: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

type AuthContextProviderProps = {
    children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
    const router = useNavigate();
    const dbName = 'gems_sponsorData';
    const [user, setUser] = useState<User>({});
    const userBS = new BehaviorSubject<User>({});


    const getUser = () => {
        const res: any = localStorage.getItem(dbName);
        return JSON.parse(res) || {};
    };

    const currentUserValue = () => {
        if (isEmptyObj(userBS.value)) {
            // setUser(getUser());
            userBS.next(getUser());
        }
        return userBS.value;
    }

    useEffect(() => {
        const d = getUser();
        if (!isEqual(currentUserValue(), d)) {
            // setUser(d);
            userBS.next(d);
        }
    }, [currentUserValue()]);

    const clearLocalStorage = () => {
        localStorage.removeItem(dbName);
        setUser({});
        userBS.next({});
    };

    const clearSubscribe = () => {
        // setUser({});
        userBS.next({});
    };

    const setApplicationUser = (newUser: User) => {
        clearLocalStorage();
        // setUser(newUser);
        userBS.next(newUser);
        localStorage.setItem(dbName, JSON.stringify(newUser));
    };

    const logout = (returnUrl: string = '') => {
        clearLocalStorage();
        clearSubscribe();
        router(`${UrlServices.AUTH_PAGE.LOGIN}${returnUrl ? `?returnUrl=${returnUrl}` : ''}`);
    };

    const loginStatus = () => {
        // if (isEmptyObj(currentUserValue())) {
        //     // setUser(getUser());
        //     userBS.next(getUser());
        // }
        return !!currentUserValue().token;
    };

    const getToken = () => {
        return currentUserValue().token || '';
    };

    const redirectToLogin = () => {
        router(UrlServices.AUTH_PAGE.LOGIN);
    };

    const redirectToDashboard = () => {
        router(UrlServices.DASHBOARD_ROUTE);
    };

    const redirectToDenied = () => {
        // router(UrlServices.ACCESS_DENIED_ROUTE);
    };


    const contextValue: AuthContextProps = {
        currentUserValue,
        setApplicationUser,
        loginStatus,
        getToken,
        clearLocalStorage,
        clearSubscribe,
        logout,
        redirectToLogin,
        redirectToDashboard,
        redirectToDenied,
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
