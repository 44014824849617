import React, { useEffect } from "react";
import { ProgressBar } from 'primereact/progressbar';



const PageLoading: React.FC<any> = () => {

    const toggleBackDrop = (status: boolean = false) => {
        const divId = 'custom-backdrop';
        const body = document.documentElement;
        const bcEl = body.getElementsByClassName('modal-backdrop');
        if (status) {
            if (!bcEl.length) {
                const bcDiv = document.createElement('div');
                bcDiv.id = divId;
                bcDiv.classList.add("modal-backdrop", "fade", "show");
                body.appendChild(bcDiv);
            } else {
                const div = document.getElementById(divId);
                div?.classList.add('show');
            }

        } else {
            const div = document.getElementById(divId);
            if (div) {
                body.removeChild(div);
            }
        }
    }
    useEffect(() => {
        // toggleBackDrop(true);
        // return () => {
        //     toggleBackDrop(false);
        // }
    })


    return (
        <>
            <ProgressBar mode="indeterminate"
                style={{ height: '3px', 'position': 'absolute', top: 0, width: '100%', 'overflow': 'hidden', 'zIndex': '9999' }}
            ></ProgressBar>
        </>
    )
}
export default PageLoading;
