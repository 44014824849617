import React, { createContext, useContext, useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

type Position = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
type Severity = 'success' | 'info' | 'warning' | 'error';
interface ToastContextProps {
    showToast: (msg?: string, type?: Severity, position?: Position) => void;
}
type ToastContextProviderProps = {
    children: React.ReactNode
}
const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider = ({ children }: ToastContextProviderProps) => {
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState<string>('');
    const [type, setType] = useState<Severity>('info');
    const [position, setPosition] = useState<any>();

    const showToast = (message: string = "We couldn't save your changes", severityType: Severity = 'info', toastPosition: Position = 'top-center') => {
        setMsg(message);
        setType(severityType);
        const p = toastPosition.split('-'),
            pos = { vertical: p.shift(), horizontal: p.pop() };
        setPosition(pos);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {

        }
    }, [open]);

    const contextValue: ToastContextProps = {
        showToast,
    };

    return (
        <ToastContext.Provider value={contextValue}>
            {children}
            <Snackbar key={Date.now()} anchorOrigin={position} open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                    {msg}
                </Alert>
            </Snackbar>
        </ToastContext.Provider>
    );
};

export const useToast = (): ToastContextProps => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};
