import { useClickOutside, useEventListener } from "primereact/hooks";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { themeProxy } from "src";
import { AppConstant } from "src/helper/app.contant";
import { MENU_ITEMS } from "src/helper/class/page-menu";
import ImageLoad from "src/helper/component/imageLoad";
import { menu } from "src/helper/interface/menu-interface";
import { useAuth } from "src/helper/provider/auth";
import { cloneData, setDropdownHightLight, toogleScreenSize, toogleThemeMode } from "src/helper/utility";

export default function Header() {
    const navBarRef = useRef(null);
    const appTheme = AppConstant.THEME_ATTRIBUTES;
    const navigate = useNavigate();
    const { logout, currentUserValue } = useAuth();

    useEffect(() => {
        const handleResize = () => { toogleScreenSize(window.innerWidth) };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        
    }, []);

    const showMobileMenu = (ev: any) => {
        if (window.innerWidth >= 1024) {
            return;
        }
        ev.preventDefault();
        const navEl = document.getElementById('nav-header') as HTMLDivElement;
        themeProxy['data-mobile'] = true;
        navEl.classList.add("mobile-top-out");
        navEl.classList.remove("mobile-top-in");
        navEl.classList.remove("mobile-top-ready");
        setTimeout(() => {
            navEl.classList.remove("mobile-top-out");
            navEl.classList.add("mobile-side-ready");
        }, 200);
        setTimeout(() => {
            navEl.classList.add("mobile-side-in");
        }, 230);
    }

    const hideMobileMenuQuick = () => {
        if (!themeProxy['data-mobile']) {
            return;
        }
        const navEl = document.getElementById('nav-header') as HTMLDivElement;
        navEl.classList.add("mobile-side-out");
        navEl.classList.remove("mobile-side-in");
        setTimeout(() => {
            navEl.classList.remove("mobile-side-ready");
            navEl.classList.remove("mobile-side-out");
            navEl.classList.add("mobile-top-ready");
        }, 200);
        setTimeout(() => {
            navEl.classList.add("mobile-top-in");
        }, 230);
        themeProxy['data-mobile'] = false;
    }

    useClickOutside(navBarRef, () => {
        hideMobileMenuQuick();
    });

    const handleMouseOver = (isLeave: boolean = false) => {
        if (appTheme["data-placement"] == 'vertical') {
            if (isLeave) {
                themeProxy['data-menu-animate'] = 'hidden';
            } else {
                themeProxy['data-menu-animate'] = 'show';

            }
        }
    }

    const onClick = (d: menu) => {
        const queryParams = new URLSearchParams(), q: any = d?.queryParams;
        if (q) {
            Object.keys(q).forEach((a: any) => {
                queryParams.append(a, q[a]);
            })
        }
        navigate(`${d?.link}${queryParams ? `?${queryParams}` : ''}`);
    }

    const getMenuItems = () => {
        let el: any = ''
        el = cloneData(MENU_ITEMS).map((item: menu, i: number) => (
            <>
                <li className="dropdown" key={i} >
                    <a data-bs-toggle={item.children?.length ? 'collapse' : ''}
                        onClick={() => { if (item.children?.length) { setDropdownHightLight(`${item.title.toLowerCase()}-parent`) }; onClick(item) }}
                        data-highlight={item.title.toLowerCase()} id={`${item.title.toLowerCase()}-parent`}
                        className={item.children?.length ? 'dropdown-toggle' : ''}  >
                        <i className={`icon ${item.icon}`}></i>
                        <span className="label">{item.title}</span>
                    </a>
                </li>
                {item?.children ?
                    <ul id={item.title.toLowerCase()} className="dropdown-menu opacityIn">
                        {item?.children.map((child, i) => (
                            <>
                                <li key={i + 'child'} >
                                    <a onClick={() => { onClick(child) }}  >
                                        <i className={`icon ${child?.icon}`}></i>
                                        <span className="label">{child.title}</span>
                                    </a>
                                </li>
                            </>
                        ))}
                    </ul>
                    : null
                }
            </>
        ))
        return el ? el : null;
    }

    return (
      <>
        <div
          ref={navBarRef}
          id="nav-header"
          key={"nav"}
          className="nav-container d-flex"
          onMouseEnter={() => handleMouseOver()}
          onMouseLeave={() => handleMouseOver(true)}
        >
          <div className="nav-content d-flex">
            <div className="logo position-relative">
              <a>
                <img className="logo-header" src={AppConstant.LOGO_SRC} />
                {/* <div className="img"></div> */}
              </a>
            </div>
            <div className="user-container d-flex">
              <a
                data-highlight="profile"
                id="profile-parent"
                className="d-flex user position-relative"
              >
                {/* <ImageLoad
                  className="profile"
                  alt="profile"
                  src="img/profile/profile-9.webp"
                /> */}
                <div className="name m-1">Welcome,</div>
                <div className="name">{currentUserValue().name}</div>
              </a>
              {/* <div id="profile" className="dropdown-menu dropdown-menu-end user-menu wide">
                            <div className="row mb-1 ms-0 me-0">
                                <div className="pe-1 ps-1">
                                    <ul className="list-unstyled">
                                        <li key={'setting'} >
                                            <a href="#">
                                                <i className="me-2 pi pi-cog" ></i>
                                                <span className="align-middle">Settings</span>
                                            </a>
                                        </li>
                                        <li key={'logout'} >
                                            <a href="#">
                                                <i className="me-2 pi pi-sign-out" ></i>
                                                <span className="align-middle">Logout</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
            </div>
            <ul
              key={"buttonul"}
              className="list-unstyled list-inline text-center menu-icons"
            >
              <li key={"logout"} className="list-inline-item ">
                <a href="" onClick={() => logout()}>
                  <div className="m-auto">LogOut</div>
                  <i className="mx-2 pi pi-sign-out"></i>
                </a>
              </li>
              {/* <li key={'colorButton'} className="list-inline-item">
                            <a href="#" id="colorButton" onClick={() => toogleThemeMode()} >
                                <i className="light pi pi-sun" ></i>
                                <i className="dark pi pi-moon" ></i>
                            </a>
                        </li> */}
            </ul>
            <div className="menu-container flex-grow-1">
              <ul id="menu" className="menu show">
                {getMenuItems()}
              </ul>
            </div>
            <div className="mobile-buttons-container">
              {/* <a href="#" id="scrollSpyButton" className="spy-button" data-bs-toggle="dropdown">
                            <i data-acorn-icon="menu-dropdown"></i>
                        </a> */}
              <div
                className="dropdown-menu dropdown-menu-end"
                id="scrollSpyDropdown"
              ></div>
              <a
                href="#"
                id="mobileMenuButton"
                className="menu-button"
                onClick={showMobileMenu}
              >
                <i className="pi pi-bars"></i>
              </a>
            </div>
          </div>
          <div className="nav-shadow"></div>
        </div>
      </>
    );
}