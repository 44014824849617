import React, { useEffect, ReactNode } from "react";
import { RouteProps, useNavigate } from "react-router-dom";
import { useAuth } from "../provider/auth";

const LoggedInGuard: React.FC<RouteProps> = ({ children, ...rest }) => {
    const { loginStatus, redirectToDashboard } = useAuth();
    const isAuthenticated = loginStatus();

    useEffect(() => {
        if (isAuthenticated) {
            redirectToDashboard();
        }
    }, [isAuthenticated]);

    return !isAuthenticated ? <>{children}</> : null;
};

export default LoggedInGuard;
