import { ConfirmDialogProps, confirmDialog } from "primereact/confirmdialog";
import { confirmPopup } from "primereact/confirmpopup";
import { AppConstant } from "./app.contant";
import { constantRes } from "./interface/response";
import { Dialog } from "primereact/dialog";
import { themeProxy } from "src";

export function isEmptyObj(obj: any) {
  if (obj && typeof obj === 'object') {
    return Object.getOwnPropertyNames(obj).length == 0;
  } else {
    return true;
  }
}

export function createSearchTableQuery(event: any) {
  if (!event)
    return null;

  const search: any = {};
  let sortText;
  if (event.first)
    search.page = (event.first / AppConstant.TABLE_PAGE_ROWS) || 1;
  else
    search.page = 0;
  search.rows = event.rows;
  if (event.filters) {
    search.queryParams = [];
    Object.keys(event.filters).forEach(key => {
      const d = event.filters[key].value || '';
      if (d instanceof Date) {
        event.filters[key].value = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
        event.filters[key].type = 'DATE'
      } else if (typeof d == 'object') {
        event.filters[key].value = null
      }
      event.filters[key]['colName'] = key;
      if (event.filters[key].value) {
        search.queryParams.push(event.filters[key]);
      }
    });
  }
  if (event.sortField) {
    search.sort = [];
    if (event.sortOrder == 1) {
      sortText = 'asc';
    }
    else if (event.sortOrder == -1) {
      sortText = 'desc';
    }
    if (event.sortField) {
      search.sort.push({ colName: event.sortField, sortOrder: sortText });
    }
  }
  return search;
}

export function sort(arr: any, prop: string, desc?: boolean) {
  arr.sort(function (a: any, b: any) {
    if (!a[prop]) return -1;
    return a[prop].localeCompare(b[prop], "en", { ignorePunctuation: true });
  });
  if (desc) arr.reverse();
  return arr;
}

export function isArray(data: any) {
  if (Array.isArray(data)) {
    return data.length;
  }
  return false;
}

export function cloneData(data: any) {
  return structuredClone(data);
}

export function diffBetweenTwoDates(fromDate: any, toDate: Date = new Date()) {
  fromDate = fromDate instanceof Date ? fromDate : new Date(fromDate);
  toDate = toDate instanceof Date ? toDate : new Date(toDate);
  const timeDiff = toDate.getTime() - fromDate.getTime();
  const res = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  return Math.abs(res)
}


export function calculateDaysLeft(targetDate: any) {
  const currentDate = new Date(),
    targetDateObject = targetDate instanceof Date ? targetDate : new Date(targetDate),
    timeDifference: number = targetDateObject.getTime() - currentDate.getTime(),
    daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysLeft;
}

export function getTaskPriorityName(id: number): constantRes {
  const data = AppConstant.PRIORITY_LEVEL;
  return data.find((a) => a.id == id) || {} as constantRes;
}

export function getTaskStatusName(id: number): constantRes {
  const data = AppConstant.TASK_STATUS;
  return data.find((a) => a.id == id) || {} as constantRes;
}

export const openConfirmPopUp = (ev: any, msg?: string, icon?: '') => {
  return new Promise((resolve) => {
    confirmPopup({
      target: ev.currentTarget,
      message: msg || 'Are you sure you want to proceed?',
      icon: icon || 'pi pi-exclamation-triangle',
      // defaultFocus: 'accept',
      accept() { resolve(true) },
      reject() { resolve(false) },
    });
  })
}

export const openConfirmDialog = (prop: ConfirmDialogProps) => {
  return new Promise((resolve) => {
    confirmDialog({
      ...prop, ...{
        draggable: false,
        message: prop.message || 'Are you sure you want to proceed ?',
        icon: prop.icon || 'pi pi-info-circle',
        // defaultFocus: 'accept',
        accept() { resolve(true) },
        reject() { resolve(false) },
      }
    });
  })
}

export const debounce = (func: Function, delay: number) => {
  let timeoutId: any;
  return function (...args: any) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export function isEqual(objA: any, objB: any) {
  if (objA === objB) return true;
  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) return false;
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) return false;
  for (const key of keysA) {
    if (!keysB.includes(key) || !isEqual(objA[key], objB[key])) return false;
  }
  return true;
}


let hightlightDrop: any = {};
export function setDropdownHightLight(id: string, recall: boolean = false) {
  const dropdownToggles = document.getElementById(id),
    highid: any = dropdownToggles?.getAttribute('data-highlight'),
    dropdownMenu = document.getElementById(highid) as HTMLElement,
    toggleRect = dropdownToggles?.getBoundingClientRect(),
    top = toggleRect?.bottom,
    left = toggleRect?.left,//dropdownToggles?.offsetLeft,
    prop = [{ prop: 'position', val: 'absolute' }, { prop: 'top', val: `${top}px` }, { prop: 'left', val: `${left}px` }, { prop: 'display', val: `block` }];
  const listener = (ev: any) => {
    const target = ev.target as HTMLElement;
    if (!dropdownMenu.contains(target) && !dropdownToggles?.contains(target)) {
      toggle();
      document.documentElement.removeEventListener('click', listener);
    }
  }
  document.documentElement.addEventListener('click', listener);
  const toggle = () => {
    prop.forEach((a: any) => {
      if (!dropdownMenu.classList.contains('show')) {
        dropdownMenu.style[a.prop] = a.val;
        dropdownMenu.setAttribute('aria-expanded', 'true');
        hightlightDrop[id] = true;
      } else {
        dropdownMenu.style.removeProperty(a.prop)
        dropdownMenu.setAttribute('aria-expanded', 'false');
        hightlightDrop[id] = false;
      }
    })
    dropdownMenu.classList.toggle('show');
    dropdownToggles?.classList.toggle('active');
    dropdownToggles?.classList.toggle('show');
    if (!recall) {
      Object.keys(hightlightDrop).forEach((a) => {
        if (hightlightDrop[a] && a != id) {
          setDropdownHightLight(a, true)
        }
      });
    }
  }
  toggle();
}

export function toogleThemeMode() {
  const attr = 'data-color',
    prev = document.documentElement.getAttribute(attr),
    sp: any = prev?.split('-'),
    col = sp?.includes('light') ? 'dark' : 'light',
    res = sp?.with(0, col),
    val = res?.join('-');
  saveTheme({ [attr]: val });
  document.documentElement.setAttribute(attr, val)
}

export function saveTheme(theme: any) {
  let prev: any = localStorage.getItem(AppConstant.THEME_STORAGE_KEY) || '{}';
  prev = JSON.parse(prev) || {};
  theme = isEmptyObj(prev) ? theme : { ...prev, ...theme }
  localStorage.setItem(AppConstant.THEME_STORAGE_KEY, JSON.stringify(theme));
}

export function toogleScreenSize(width: number) {
  const theme = cloneData(AppConstant.THEME_ATTRIBUTES);
  if (width >= 1024) {
    themeProxy['data-dimension'] = 'desktop'
    themeProxy['data-placement'] = theme["data-placement"];
    themeProxy['data-behaviour'] = theme['data-behaviour']
  } else {
    themeProxy['data-placement'] = 'horizontal'
    themeProxy['data-dimension'] = 'mobile'
    themeProxy['data-behaviour'] = 'pinned'
  }
}