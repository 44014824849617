// Routes.tsx
import React, { Component, LazyExoticComponent, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthRoute from '../pages/auth/auth.route';
import DeafultRoute from '../pages/default/default.route';
import PageLoading from './component/pageloader';

export type TRoute = {
    name: string;
    path: string;
    Component: LazyExoticComponent<() => JSX.Element>;
};

// const basename = window.location.pathname ? '/subfolder' : '/';

const AppRoutes: React.FC = () => {
    return (
        <Suspense fallback={<PageLoading />}>
            <Routes>
                <Route path="/auth/*" element={<AuthRoute />} />
                <Route path="/*" element={<DeafultRoute />} />
                <Route path="*" element={<h1>Not found</h1>} />
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
