export class UrlServices {
    public static DASHBOARD_ROUTE = '/' + 'dashboard'
    public static NOT_FOUND = '/' + 'not-found'
    public static AUTH_PAGE = {
        LOGIN: '/auth' + '/' + 'login'
    }
    public static PAGES = {
        TODO: '/' + 'todo',
        DONATION_LIST: '/' + 'donation-list',
        SPONSORSHIP_LIST: '/' + 'sponsorship-list',
        NEW_SPONSORSHIP: '/' + 'new-sponsorship',
        MONTHLY_REPORT_LIST: '/' + 'monthly-report-list',
    }
};
