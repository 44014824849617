import React, { useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";
import { useAuth } from "../provider/auth";

const AuthGuard: React.FC<RouteProps> = ({ children, ...rest }) => {
    const { loginStatus, logout } = useAuth();
    const isAuthenticated = loginStatus();

    useEffect(() => {
        if (!isAuthenticated) {
            logout();
        }
    }, [isAuthenticated, logout]);

    return isAuthenticated ? <>{children}</> : null;
};

export default AuthGuard;
