import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import Header from 'src/core/components/header';
import { UrlServices } from 'src/helper/class/url.service';
import { routes } from '../../helper/routes';
import AuthGuard from 'src/helper/guards/auth-guard';
import PageLoading from 'src/helper/component/pageloader';


const redirect: string = UrlServices.DASHBOARD_ROUTE;
const notFound: string = UrlServices.NOT_FOUND;


const DeafultRoute: React.FC = () => {

    const isPageNotFound = useMatch(notFound);
    return (
        <>
            {!isPageNotFound && <Header key={'header'} />}
            <Suspense fallback={<PageLoading />}>
                <main>
                    <div className="container">
                        <Routes>
                            {routes.map(({ name, path, Component }, index) => (
                                <Route
                                    key={name + index}
                                    path={path}
                                    element={
                                        // <Component />
                                        <AuthGuard ><Component /></AuthGuard>
                                    }
                                />
                            ))}
                            <Route key={'defaultpath'} path="/" element={<Navigate replace to={redirect} />} />
                            <Route path="*" key={'notfoundpath'} element={<Navigate replace to={notFound} />} />
                        </Routes>
                    </div>
                </main>
            </Suspense >
        </>
    );
};

export default DeafultRoute;
