import { PrimeReactProvider } from 'primereact/api';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup } from 'primereact/confirmpopup';
import './App.css';
import AppRoutes from './helper/app.route';
import { AuthProvider } from './helper/provider/auth';

function App() {
  const primeReactVal = {
    ripple: true,
  };
  return (
    <>
      <PrimeReactProvider value={primeReactVal}>
        <AuthProvider>
          <AppRoutes />
          <ConfirmPopup />
          <ConfirmDialog />
        </AuthProvider>
      </PrimeReactProvider>
    </>
  );
}

export default App;
