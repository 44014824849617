import { lazy } from 'react';
import { TRoute } from './app.route';
import { UrlServices } from './class/url.service';

const notFound = lazy(() => import('../pages/default/components/page-not-found'));
const toDO = lazy(() => import('../pages/default/todo/todolist'));
const dashboard = lazy(() => import('../pages/default/components/dashboard'));
const donationList = lazy(() => import('../pages/default/donation/donation-list'));
const sponsorshipList = lazy(() => import('../pages/default/sponsorship/sponsorship-list'));
const newSponsorship = lazy(() => import('../pages/default/sponsorship/new-sponsorship'));
const montlyReportList = lazy(() => import('../pages/default/monthly-report/monthly-report-list'));

export const routes = [
    {
        name: 'Notfound',
        path: UrlServices.NOT_FOUND.split(/[/]+/).pop(),
        Component: notFound,
    },
    {
        name: 'Dashboard',
        path: UrlServices.DASHBOARD_ROUTE.split(/[/]+/).pop(),
        Component: dashboard,
    },
    {
        name: 'ToDO',
        path: UrlServices.PAGES.TODO.split(/[/]+/).pop(),
        Component: toDO,
    },
    {
        name: 'Donation List',
        path: UrlServices.PAGES.DONATION_LIST.split(/[/]+/).pop(),
        Component: donationList,
    },
    {
        name: 'Sponsorship List',
        path: UrlServices.PAGES.SPONSORSHIP_LIST.split(/[/]+/).pop(),
        Component: sponsorshipList,
    },
    {
        name: 'New Sponsorship',
        path: UrlServices.PAGES.NEW_SPONSORSHIP.split(/[/]+/).pop(),
        Component: newSponsorship,
    },
    {
        name: 'Monthly Report List',
        path: UrlServices.PAGES.MONTHLY_REPORT_LIST.split(/[/]+/).pop(),
        Component: montlyReportList,
    },
] as TRoute[];
