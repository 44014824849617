import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UrlServices } from 'src/helper/class/url.service';
import LoggedInGuard from 'src/helper/guards/loggedIn-guard';
import { TRoute } from '../../helper/app.route';
import PageLoading from 'src/helper/component/pageloader';

// if auth modules route empty redirect URL 
const redirect: string = UrlServices.AUTH_PAGE.LOGIN;

const Login = lazy(() => import('./components/login'));
const routes = [
    {
        name: 'Login',
        path: UrlServices.AUTH_PAGE.LOGIN.split(/[/]+/).pop(),
        Component: Login,
    }
] as any as TRoute[];

const AuthRoute: React.FC = () => {
    return (
        <Suspense fallback={<PageLoading />}>
            <Routes>
                {routes.map(({ name, path, Component }, index) => (
                    <Route
                        key={name + index}
                        path={path}
                        element={
                            <LoggedInGuard><Component /></LoggedInGuard>
                        }
                    />
                ))}
                <Route path="/" element={<Navigate replace to={redirect} />} />
            </Routes>
        </Suspense>
    );
};

export default AuthRoute;
