import logo from 'src/assets/images/logo/gems-logo.png';
import defaultImg from 'src/assets/images/blank.webp';
export class AppConstant {
    public static DATE_FORMAT = 'dd-mm-yy';
    public static TITLE = 'Gems';
    public static ORG_NAME = 'GEMS';
    static LOGO_SRC = logo//'assets/images/gems-logo.png';
    static DEFAULT_PROFILE_IMAGE = defaultImg//'src/assets/images/blank.webp';
    static DEFAULT_DATE_RANGE = '1940:' + new Date().getFullYear().toString();
    static DEFAULT_FUTURE_DATE_RANGE = '1960:' + (new Date().getFullYear() + 5).toString();
    public static GENDER = [
        { id: 1, genderName: 'Male' },
        { id: 2, genderName: 'Female' },
    ];
    public static OTPDIGIT = 4;
    public static TABLE_PAGE_ROWS = 10;

    public static PRIORITY_LEVEL = [
        { name: 'High', color: 'danger', id: 1 },
        { name: 'Medium', color: 'warning', id: 2 },
        { name: 'Low', color: 'success', id: 3 },
    ];
    public static TASK_STATUS = [
        { name: 'Incomplete', color: 'danger', id: 1 },
        { name: 'Inprogress', color: 'warning', id: 2 },
        { name: 'To-do', color: 'primary', id: 3 },
        { name: 'Completed', color: 'success', id: 4 },
        { name: 'Cancelled', color: 'danger', id: 5 },
    ];
    public static THEME_STORAGE_KEY = 'theme'
    public static THEME_ATTRIBUTES = {
        'data-placement': 'vertical',
        'data-behaviour': 'unpinned',
        'data-layout': 'fluid',
        'data-radius': 'rounded',
        'data-color': 'light-red',
        'data-navcolor': 'default',
        'data-dimension': "desktop",
        'data-menu-animate': "hidden",
        'data-footer': "true"
    }

}